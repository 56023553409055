.my-1 {
    margin: 4px 0px !important;
}

.my-2 {
    margin: 8px 0px !important;
}

.my-3 {
    margin: 16px 0px !important;
}

.my-4 {
    margin: 32px 0px !important;
}

.mx-1 {
    margin: 0px 4px !important;
}

.mx-2 {
    margin: 0px 8px !important;
}

.mx-3 {
    margin: 0px 16px !important;
}

.mx-4 {
    margin: 0px 32px !important;
}

.pointer:hover {
    cursor: pointer;
}

.flex-row {
    display: flex;
    flex-direction: row;
    position: relative;
}

.grid {
    display: flex;
    flex-wrap: wrap;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-4 {
    flex: 0 0 33%;
    max-width: 33%;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

#print-content {
    display: none;
}

.search-highlight {
    color: #b5ad42;
}

.ql-direction-rtl {
    direction: rtl !important;
}

blockquote {
    border-left: none !important;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 40px !important;
    margin-inline-end: 40px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    padding-left: 16px !important;
}

@media print {
    .print-hidden {
        display: none !important;
    }

    #print-content {
        display: block;
        position: absolute;

        overflow: show;
    }

    #print-content button {
        display: none;
    }
}

@page {
    margin: 2cm;
}

.relative {
    position: relative;
}

.tutorial-film-1 {
    padding: 15px 0;
}

.tutorial-film-2 {
    right: 16px;
    position: absolute;
    top: 19px;
}

.tutorial-film-4 {
    position: absolute;
    right: 16px;
    top: 17px;
}

.tutorial-film-6 {
    position: absolute;
    left: 58px;
    top: 13px;
}

@media screen and (max-width: 991px) {
    .only-desktop {
        display: none !important;
    }
}
@media screen and (min-width: 992px) {
    .only-mobile {
        display: none !important;
    }
}
