/* Title Style */
#translation .rst__rowTitle {
    font-size: 12px;
    font-weight: bold;
    color: #262626;
    width: 100%;
    height: 100%;
}

/* Styling the collapse and expand buttons */
/* .ReactVirtualized__Grid {
    width: 250px !important;
} */

#translation .rst__collapseButton,
#translation .rst__expandButton {
    height: 24px;
    width: 24px;
    color: #FFFFFF;
    box-shadow: none;
}

#translation .rst__collapseButton:focus,
#translation .rst__collapseButton:focus {
    box-shadow: none;
}

#translation .rst__expandButton {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white' width='18px' height='18px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'/%3E%3C/svg%3E") no-repeat center;
    background-color: #646464;
}

#translation .rst__collapseButton {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white' width='18px' height='18px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 13H5v-2h14v2z'/%3E%3C/svg%3E") no-repeat center;
    background-color: #F1592A;
}

/* Styling the collapse and expand buttons */
#translation .rst__collapseButton:hover:not(:active),
#translation .rst__expandButton:hover:not(:active) {
    height: 24px;
    width: 24px;
    background-size: initial;
}

#translation .rst__collapseButton:focus,
#translation .rst__expandButton:focus {
    outline: none;
    box-shadow: none;
}


/* Styling the node item */
#translation .rst__moveHandle,
#translation .rst__rowContents {
    border: none;
    /* border-top: 1px dashed #262626; */
    /* border-bottom: 1px dashed #262626; */
    background-color: transparent;
    box-shadow: none;
    border-radius: 0px;
}

#translation .rst__moveHandle {
    /* border-left: 1px dashed #262626;  */
    margin-right: 2px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='16px' height='16px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z'/%3E%3C/svg%3E") no-repeat center;
    cursor: grab;
}

#translation .rst__moveHandle:active {
    cursor: grabbing
}

/* #translation .rst__rowContents{
     border-right: 1px dashed #262626; 
} */
#translation .rst__rowWrapper {
    padding: 0;
}


/* Removing the right connector */
#translation .rst__lineHalfVerticalBottom::before,
#translation .rst__lineHalfHorizontalRight::before {
    display: none;
}

/* Making the width 100% */
#translation .rst__nodeContent {
    right: 0;
}

#translation .rst__rowContents {
    padding: 0;
}

#translation .rst__rowLabel {
    flex: auto !important;
    padding-right: 0;
    width: 100%;
    height: 100%;
}

#translation .rst__lineHalfHorizontalRight::before,
#translation .rst__lineFullVertical::after,
#translation .rst__lineHalfVerticalTop::after,
#translation .rst__lineHalfVerticalBottom::after {
    position: inherit;
}

#translation .rst__lineChildren::after {
    width: 0px;
}

/* Icon for the content node. */
#translation .rst__contentNode::before {
    content: "";
    height: 24px;
    width: 24px;
    color: #fff;
    left: -33px;
    top: 17px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white' width='12px' height='12px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z'/%3E%3C/svg%3E") no-repeat center;
    position: absolute;
    background-color: #000;
    border-radius: 100%;
}

#translation .rst__rowSearchMatch {
    outline: none !important;
}