body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Helvetica Neue', 'Helvetica', 'Arial', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ql-editor  p{
    margin-bottom: 1em !important;
}

.my-1 {
    margin: 4px 0px !important;
}

.my-2 {
    margin: 8px 0px !important;
}

.my-3 {
    margin: 16px 0px !important;
}

.my-4 {
    margin: 32px 0px !important;
}

.mx-1 {
    margin: 0px 4px !important;
}

.mx-2 {
    margin: 0px 8px !important;
}

.mx-3 {
    margin: 0px 16px !important;
}

.mx-4 {
    margin: 0px 32px !important;
}

.pointer:hover {
    cursor: pointer;
}

.flex-row {
    display: flex;
    flex-direction: row;
    position: relative;
}

.grid {
    display: flex;
    flex-wrap: wrap;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-4 {
    flex: 0 0 33%;
    max-width: 33%;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

#print-content {
    display: none;
}

.search-highlight {
    color: #b5ad42;
}

.ql-direction-rtl {
    direction: rtl !important;
}

blockquote {
    border-left: none !important;
    -webkit-margin-before: 1em !important;
            margin-block-start: 1em !important;
    -webkit-margin-after: 1em !important;
            margin-block-end: 1em !important;
    -webkit-margin-start: 40px !important;
            margin-inline-start: 40px !important;
    -webkit-margin-end: 40px !important;
            margin-inline-end: 40px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    padding-left: 16px !important;
}

@media print {
    .print-hidden {
        display: none !important;
    }

    #print-content {
        display: block;
        position: absolute;

        overflow: show;
    }

    #print-content button {
        display: none;
    }
}

@page {
    margin: 2cm;
}

.relative {
    position: relative;
}

.tutorial-film-1 {
    padding: 15px 0;
}

.tutorial-film-2 {
    right: 16px;
    position: absolute;
    top: 19px;
}

.tutorial-film-4 {
    position: absolute;
    right: 16px;
    top: 17px;
}

.tutorial-film-6 {
    position: absolute;
    left: 58px;
    top: 13px;
}

@media screen and (max-width: 991px) {
    .only-desktop {
        display: none !important;
    }
}
@media screen and (min-width: 992px) {
    .only-mobile {
        display: none !important;
    }
}

/* Title Style */
#translation .rst__rowTitle {
    font-size: 12px;
    font-weight: bold;
    color: #262626;
    width: 100%;
    height: 100%;
}

/* Styling the collapse and expand buttons */
/* .ReactVirtualized__Grid {
    width: 250px !important;
} */

#translation .rst__collapseButton,
#translation .rst__expandButton {
    height: 24px;
    width: 24px;
    color: #FFFFFF;
    box-shadow: none;
}

#translation .rst__collapseButton:focus,
#translation .rst__collapseButton:focus {
    box-shadow: none;
}

#translation .rst__expandButton {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white' width='18px' height='18px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'/%3E%3C/svg%3E") no-repeat center;
    background-color: #646464;
}

#translation .rst__collapseButton {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white' width='18px' height='18px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 13H5v-2h14v2z'/%3E%3C/svg%3E") no-repeat center;
    background-color: #F1592A;
}

/* Styling the collapse and expand buttons */
#translation .rst__collapseButton:hover:not(:active),
#translation .rst__expandButton:hover:not(:active) {
    height: 24px;
    width: 24px;
    background-size: auto auto;
    background-size: initial;
}

#translation .rst__collapseButton:focus,
#translation .rst__expandButton:focus {
    outline: none;
    box-shadow: none;
}


/* Styling the node item */
#translation .rst__moveHandle,
#translation .rst__rowContents {
    border: none;
    /* border-top: 1px dashed #262626; */
    /* border-bottom: 1px dashed #262626; */
    background-color: transparent;
    box-shadow: none;
    border-radius: 0px;
}

#translation .rst__moveHandle {
    /* border-left: 1px dashed #262626;  */
    margin-right: 2px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='16px' height='16px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z'/%3E%3C/svg%3E") no-repeat center;
    cursor: -webkit-grab;
    cursor: grab;
}

#translation .rst__moveHandle:active {
    cursor: -webkit-grabbing;
    cursor: grabbing
}

/* #translation .rst__rowContents{
     border-right: 1px dashed #262626; 
} */
#translation .rst__rowWrapper {
    padding: 0;
}


/* Removing the right connector */
#translation .rst__lineHalfVerticalBottom::before,
#translation .rst__lineHalfHorizontalRight::before {
    display: none;
}

/* Making the width 100% */
#translation .rst__nodeContent {
    right: 0;
}

#translation .rst__rowContents {
    padding: 0;
}

#translation .rst__rowLabel {
    flex: auto !important;
    padding-right: 0;
    width: 100%;
    height: 100%;
}

#translation .rst__lineHalfHorizontalRight::before,
#translation .rst__lineFullVertical::after,
#translation .rst__lineHalfVerticalTop::after,
#translation .rst__lineHalfVerticalBottom::after {
    position: inherit;
}

#translation .rst__lineChildren::after {
    width: 0px;
}

/* Icon for the content node. */
#translation .rst__contentNode::before {
    content: "";
    height: 24px;
    width: 24px;
    color: #fff;
    left: -33px;
    top: 17px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white' width='12px' height='12px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z'/%3E%3C/svg%3E") no-repeat center;
    position: absolute;
    background-color: #000;
    border-radius: 100%;
}

#translation .rst__rowSearchMatch {
    outline: none !important;
}

[class^='MuiInput-underline-'], [class*=' MuiInput-underline-'] {
    border: none

}
